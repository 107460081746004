import { UseMutationResult, UseQueryResult, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import {
  addFreeDaysEndpoint,
  checkWorkingTimeEndpoint,
  createUserEndpoint,
  createWorkTimeEndpoint,
  deleteFreeDaysEndpoint,
  getDeleteUserEndpoint,
  getGetAllUsersEndpoint,
  getGetUserByEndpoint,
  getUpdateUserEndpoint,
} from "../endpoints";
import { httpClient } from "../network";
import { toast } from "react-toastify";
import { getGetRoleByEndpoint } from "../endpoints/rolesEndpoint";
import {
  IAddFreeDaysPayload,
  IAddFreeDaysResponse,
  ICreateUserRequest,
  ICreateUserResponse,
  ICreateWorkTimeRequest,
  ICreateWorkTimeResponse,
  IDeleteUserResponse,
  IGetAllUsersRequest,
  IGetAllUsersResponse,
  IGetConflict,
  IGetRoles,
  IGetUserByRequest,
  IGetUserByResponse,
  IUpdateUserRequest,
  IUpdateUserResponse,
} from "../../../types";
import { useDebounce } from 'use-debounce';

export const useCreateUserService = (): UseMutationResult<ICreateUserResponse, unknown, ICreateUserRequest> => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (createUserPayload: ICreateUserRequest) => {
      const response: AxiosResponse<ICreateUserResponse> = await httpClient.post(createUserEndpoint(), createUserPayload);
      return response.data;
    },
    onSuccess: () => {
      toast.success("Korisnik je uspješno kreiran");
      queryClient.invalidateQueries({ queryKey: ["getAllUsers"] });
    },
    onError: () => toast.error("Korisnik nije uspješno kreiran"),
  });
  return mutation;
};

export const useCreateWorkTimeService = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: ["createWorkTime"],
    mutationFn: async ({ createWorkTimePayload, id }: { createWorkTimePayload: ICreateWorkTimeRequest; id: string }) => {
      const response: AxiosResponse<ICreateWorkTimeResponse> = await httpClient.post(createWorkTimeEndpoint(id), createWorkTimePayload);
      return response.data;
    },
    onSuccess: () => {
      toast.success("Radno vrijeme je uspješno ažurirano");
      queryClient.invalidateQueries({ queryKey: ["getAllUsers"] });
    },
    onError: () => toast.error("Radno vreme nije uspješno kreirano"),
  });

  return mutation;
};

export const useAddFreeDaysService = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: ["addFreeDays"],
    mutationFn: async ({ addFreeDaysPayload, id }: { addFreeDaysPayload: IAddFreeDaysPayload; id: string }) => {
      const response: AxiosResponse<IAddFreeDaysResponse> = await httpClient.post(addFreeDaysEndpoint(id), addFreeDaysPayload);
      return response.data;
    },
    onSuccess: () => {
      toast.success("Slobodni dani uspješno dodani");
      queryClient.invalidateQueries({ queryKey: ["getUserBy"] });
    },
    onError: () => toast.error("Slobodni dani nisu dodani"),
  });
  return mutation;
};

export const useUpdateUserService = (): UseMutationResult<IUpdateUserResponse, unknown, IUpdateUserRequest> => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (updateUserPayload: IUpdateUserRequest) => {
      const response: AxiosResponse<IUpdateUserResponse> = await httpClient.put(
        getUpdateUserEndpoint(updateUserPayload.userId),
        updateUserPayload.updateUserData
      );
      return response.data;
    },
    onSuccess: () => {
      toast.success("Korisnik je uspješno ažuriran");
      queryClient.invalidateQueries({ queryKey: ["getUserBy"] });
    },
    onError: () => toast.error("Korisnik nije uspješno ažuriran"),
  });

  return mutation;
};
export const useDeleteUserService = (): UseMutationResult<IDeleteUserResponse, unknown, string> => {
  const mutation = useMutation({
    mutationFn: async (userId: string) => {
      const response: AxiosResponse<IDeleteUserResponse> = await httpClient.delete(getDeleteUserEndpoint(userId));
      return response.data;
    },
    onSuccess: () => {
      localStorage.setItem("deletionSuccess", "true");
      if (typeof window !== "undefined") 
        (window.location as Location).href = "/dashboard/employees";
    },
    onError: () => toast.error("Korisnik nije uspješno obrisan"),
  });

  return mutation;
};

export const useDeleteFreeDaysService = () => {
  const queryClient = useQueryClient();
  const query = useMutation({
    mutationFn: async (freeDaysId: string) => {
      const response: AxiosResponse = await httpClient.delete(deleteFreeDaysEndpoint(freeDaysId));
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getUserBy"] });
      toast.success("Slobodni dani uspješno obrisani");
    },
    onError: () => toast.error('Slobodni dani nisu uspješno obrisani')
  });
  return query;
};

export const useGetAllUsersService = (getAllUsersQueries: IGetAllUsersRequest): UseQueryResult<IGetAllUsersResponse, unknown> => {
  const query = useQuery({
    queryKey: ["getAllUsers", getAllUsersQueries],
    queryFn: async () => {
      const response: AxiosResponse<IGetAllUsersResponse> = await httpClient.get(getGetAllUsersEndpoint(getAllUsersQueries));
      return response.data;
    },
    retry: 0,
  });
  return query;
};

export const useGetUserByService = (getUserByQueries: IGetUserByRequest): UseQueryResult<IGetUserByResponse, unknown> => {
  const query = useQuery({
    queryKey: ["getUserBy", getUserByQueries],
    queryFn: async () => {
      const response: AxiosResponse<IGetUserByResponse> = await httpClient.get(getGetUserByEndpoint(getUserByQueries));
      return response.data;
    },
  });
  return query;
};

export const useGetRoles = (): UseQueryResult<IGetRoles, unknown> => {
  const query = useQuery({
    queryKey: ["getRoles"],
    queryFn: async () => {
      const response: AxiosResponse<IGetRoles> = await httpClient.get(getGetRoleByEndpoint());
      return response.data;
    },
  });
  return query;
};

export const useCheckWorkingTimeConflict = (queryParams:{
  staffId: string,
  day: number,
  startTime: string,
  isAvailable: boolean,
  startBreak: string,
  endBreak: string
}): UseQueryResult<IGetConflict, unknown> => {
  const [debouncedQueryParams] = useDebounce(queryParams, 1000);

  return useQuery({
    queryKey: ['checkWorkingHours', debouncedQueryParams],
    queryFn: async () => {
      const response:AxiosResponse<IGetConflict> = await httpClient.get(checkWorkingTimeEndpoint(
        debouncedQueryParams.staffId,
        debouncedQueryParams.day,
        debouncedQueryParams.startTime,
        debouncedQueryParams.isAvailable,
        debouncedQueryParams.startBreak,
        debouncedQueryParams.endBreak
      ));
      return response.data;
    },
    enabled: true
  })
}
