import {
    keepPreviousData,
    useMutation,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryResult
} from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import {IEquipment, IEquipmentPayload, IEquipmentResponse} from "../../../types";
import { httpClient } from '../network'
import {createEquipmentEndpoint, deleteEquipmentEndpoint, getByEquipmentEndpoint, getEquipmentEndpoint, updateEquipmentEndpoint} from "../endpoints/equipmentEndpoint";

export const useCreateEquipmentService =
    (): UseMutationResult<IEquipmentResponse, unknown, IEquipment> => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async (createEquipmentPayload: IEquipment) => {
            try {
                const response: AxiosResponse<IEquipmentResponse> =
                    await httpClient.post(createEquipmentEndpoint(), createEquipmentPayload);
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        onSuccess: () => {
            toast.success("Oprema je uspešno kreirana");
            queryClient.invalidateQueries({ queryKey: ["getAllEquipment"] });
        },
        onError: (error: unknown) => {
            toast.error(`Oprema nije uspešno kreirana: ${(error as Error).message}`);
        },
    });

    return mutation;
};

export const useGetAllEquipmentService = (getAllEquipmentQueries: any): UseQueryResult<IEquipmentResponse, unknown> => {
    const query = useQuery({
        queryKey: ["getAllEquipment", getAllEquipmentQueries],
        queryFn: async () => {
            const response: AxiosResponse<IEquipmentResponse> = await httpClient.get(getEquipmentEndpoint());
            return response.data;
        },
        retry: 0,
        placeholderData: keepPreviousData,
    });
    return query;
};

export const useGetByEquipmentService = (id: string): UseQueryResult<IEquipmentResponse, unknown> => {
    return useQuery({
        queryKey: ["getByEquipment", id],
        queryFn: async () => {
            const response: AxiosResponse<IEquipmentResponse> = await httpClient.get(getByEquipmentEndpoint(id));
            return response.data;
        },
        retry: 0,
    })
}

export const useDeleteEquipmentService = (): UseMutationResult<IEquipmentResponse, unknown, string> => {
  return useMutation({
    mutationFn: async (id: string) => {
      const response: AxiosResponse<IEquipmentResponse> = await httpClient.delete(deleteEquipmentEndpoint(id));
      return response.data;
    },
    onSuccess: () => {
      toast.success("Oprema je uspješno obrisana");
    },
    onError: (error: unknown) => {
      toast.error(`Oprema nije uspješno obrisana: ${(error as Error).message}`);
    },
  })
}



export const useUpdateEquipmentService = (): 
  UseMutationResult<IEquipmentResponse, unknown, IEquipmentPayload> => {
    const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (payload: IEquipmentPayload) => {
        payload.data.quantity = Number(payload.data.quantity)
        const response: AxiosResponse<IEquipmentResponse> = await httpClient.put(updateEquipmentEndpoint(payload.id), payload.data);
        return response.data;
      },
      onSuccess: () => {
        toast.success("Oprema je uspješno ažurirana");
        queryClient.invalidateQueries({ queryKey: ["getByEquipment"] });
      },
      onError: (error: unknown) => {
        toast.error(`Oprema nije uspješno ažurirana`);
      },
  })
}
