import { IGetAllClientsRequest, IGetClientByRequest } from "../../../types";

const API_PREFIX = "/clients";

export const createClientEndpoint = () => `${API_PREFIX}`;
export const getGetAllClientsEndpoint = (queries: IGetAllClientsRequest) =>
  `${API_PREFIX}${queries.pageSize && queries.page ? `?page=${queries.page}&pageSize=${queries.pageSize}&search=${queries.search || ''}` : ""}`;
export const getGetClientByEndpoint = (getClientByQueries: IGetClientByRequest) =>
  `${API_PREFIX}/getBy?prop=${getClientByQueries.prop}&value=${getClientByQueries.value}`;
export const getUpdateClientEndpoint = (clientId: string) => `${API_PREFIX}/${clientId}`;
export const getDeleteClientEndpoint = (clientId: string) => `${API_PREFIX}/${clientId}`;
export const uploadEndpoint = (clientId: string) => `${API_PREFIX}/files?clientId=${clientId}`;
export const getUploadsEndpoint = (clientId: string) => `${API_PREFIX}/files/${clientId}`;
export const deleteFileEndpoint = (key: string) => `${API_PREFIX}/files/${key}`;
