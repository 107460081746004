import { UseMutationResult, UseQueryResult, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import {
  getCreateServiceEndpoint,
  getDeleteServiceEndpoint,
  getGetAllServicesEndpoint,
  getGetServiceByEndpoint,
  getUpdateServiceEndpoint,
} from "../endpoints";
import { httpClient } from "../network";
import { toast } from "react-toastify";
import {
  ICreateServiceRequest,
  ICreateServiceResponse,
  IDeleteServiceResponse,
  IGetAllServicesRequest,
  IGetAllServicesResponse,
  IGetServiceByRequest,
  IGetServiceByResponse,
  IUpdateServiceRequest,
  IUpdateServiceResponse,
} from "../../../types";

export const useCreateServiceService = (): UseMutationResult<ICreateServiceResponse, unknown, ICreateServiceRequest> => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (createServicePayload: ICreateServiceRequest) => {
      const response: AxiosResponse<ICreateServiceResponse> = await httpClient.post(getCreateServiceEndpoint(), createServicePayload);
      return response.data;
    },
    onSuccess: () => {
      toast.success("Usluga je uspješno kreirana");
      queryClient.invalidateQueries({ queryKey: ["getAllServices"] });
    },
    onError: () => {
      toast.error("Usluga nije uspješno kreirana")
    },
  });

  return mutation;
};
export const useUpdateServiceService = (): UseMutationResult<IUpdateServiceResponse, unknown, IUpdateServiceRequest> => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (updateServicePayload: IUpdateServiceRequest) => {
      const response: AxiosResponse<IUpdateServiceResponse> = await httpClient.put(
        getUpdateServiceEndpoint(updateServicePayload.serviceId),
        updateServicePayload.updateServiceData
      );
      return response.data;
    },
    onSuccess: () => {
      toast.success("Usluga je uspješno ažurirana");
      queryClient.invalidateQueries({ queryKey: ["getServiceBy"] });
    },
    onError: () => {
      toast.error("Usluga nije uspješno ažurirana")
    },
  });

  return mutation;
};
export const useDeleteServiceService = (): UseMutationResult<IDeleteServiceResponse, unknown, string> => {
  const mutation = useMutation({
    mutationFn: async (serviceId: string) => {
      const response: AxiosResponse<IDeleteServiceResponse> = await httpClient.delete(getDeleteServiceEndpoint(serviceId));
      return response.data;
    },
    onSuccess: () => {
      localStorage.setItem("deletionSuccess", "true");
        if (typeof window !== "undefined") 
          (window.location as Location).href = "/dashboard/services";
    },
    onError: () => {
      toast.error("Usluga nije uspješno obrisana")
    },
  });

  return mutation;
};

export const useGetAllServicesService = (getAllServicesRequest: IGetAllServicesRequest): UseQueryResult<IGetAllServicesResponse, unknown> => {
  const query = useQuery({
    queryKey: ["getAllServices", getAllServicesRequest],
    queryFn: async () => {
      const response: AxiosResponse<IGetAllServicesResponse> = await httpClient.get(getGetAllServicesEndpoint(getAllServicesRequest));
      return response.data;
    },
    retry: 0,
  });
  return query;
};

export const useGetServiceByService = (getServiceByQueries: IGetServiceByRequest): UseQueryResult<IGetServiceByResponse, unknown> => {
  const query = useQuery({
    queryKey: ["getServiceBy", getServiceByQueries],
    queryFn: async () => {
      const response: AxiosResponse<IGetServiceByResponse> = await httpClient.get(getGetServiceByEndpoint(getServiceByQueries));
      return response.data;
    },
    retry: 0,
  });
  return query;
};
