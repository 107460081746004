import { UseMutationResult, UseQueryResult, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import {
  editReservationEndpoint,
  getCreateReservationEndpoint,
  getGetAllReservationsEndpoint,
  getGetAvailableAppointmentsEndpoint,
  getGetReservationByEndpoint,
} from "../endpoints";
import { httpClient } from "../network";
import { toast } from "react-toastify";
import {
  ICreateReservationRequest,
  ICreateReservationResponse,
  IEditReservationRequest,
  IEditReservationResponse,
  IGetAllReservationsRequest,
  IGetAllReservationsResponse,
  IGetAvailableAppointmentsRequest,
  IGetAvailableAppointmentsResponse,
  IGetReservationByRequest,
  IGetReservationByResponse,
} from "../../../types";

export const useCreateReservationService = (): UseMutationResult<ICreateReservationResponse, unknown, ICreateReservationRequest> => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (createReservationPayload: ICreateReservationRequest) => {
      const response: AxiosResponse<ICreateReservationResponse> = await httpClient.post(getCreateReservationEndpoint(), createReservationPayload);
      return response.data;
    },
    onSuccess: () => {
      toast.success("Rezervacija je uspješno kreirana");
      queryClient.invalidateQueries({ queryKey: ["getAllReservations"] });
      queryClient.invalidateQueries({ queryKey: ["getAdminPanelStatistic"] });
    },
    onError: () => {
      toast.error("Rezervacija nije uspješno kreirana")
    },
  });

  return mutation;
};

export const useEditReservationService = (id: string) => {
  const query = useQueryClient();
  const mutation = useMutation({
    mutationKey: ["editReservation"],
    mutationFn: async (editReservationPayload: IEditReservationRequest) => {
      const response: AxiosResponse<IEditReservationResponse> = await httpClient.put(editReservationEndpoint(id), editReservationPayload);
      if(editReservationPayload.discount === undefined) {
        query.invalidateQueries({ queryKey: ["getClientBy"]});
        query.invalidateQueries({ queryKey: ["recordItems"]});
      } else {
        query.invalidateQueries({ queryKey: ["getReservationBy"] });
      }
      return response.data;
    },
    onSuccess: () => {
      toast.success("Rezervacija je uspješno ažurirana");
      query.invalidateQueries({ queryKey: ["getReservationBy"] });
      query.invalidateQueries({ queryKey: ["getClientBy"]});
      query.invalidateQueries({ queryKey: ["recordItems"]});
    },
    onError: () => {
      toast.error("Rezervacija nije uspješno ažurirana")
    },
  });

  return mutation;
};

export const useGetAvailableAppointmentsService = (): UseMutationResult<
  IGetAvailableAppointmentsResponse,
  unknown,
  IGetAvailableAppointmentsRequest
> => {
  const mutation = useMutation({
    mutationFn: async (getAvailableAppointmentsPayload: IGetAvailableAppointmentsRequest) => {
      const response = await httpClient.post(getGetAvailableAppointmentsEndpoint(getAvailableAppointmentsPayload), getAvailableAppointmentsPayload);
      return response.data;
    },
  });

  return mutation;
};

export const useGetAllReservationsService = (
  getAllReservationsQueries: IGetAllReservationsRequest
): UseQueryResult<IGetAllReservationsResponse, unknown> => {
  const query = useQuery({
    queryKey: ["getAllReservations", getAllReservationsQueries],
    queryFn: async () => {
      const conditions = {
        //@ts-ignore
        from: new Date(getAllReservationsQueries?.conditions?.from).toLocaleDateString('en-CA') + 'T00:00:00.000Z',
        //@ts-ignore
        to: new Date(getAllReservationsQueries?.conditions?.to).toLocaleDateString('en-CA') + 'T23:59:59.000Z',
      }
      //@ts-ignore
      getAllReservationsQueries.conditions = conditions;
      
      const response: AxiosResponse<IGetAllReservationsResponse> = await httpClient.get(getGetAllReservationsEndpoint(getAllReservationsQueries));
      return response.data;
    },
    retry: 0,
  });
  return query;
};

export const useGetReservationByService = (getReservationByQueries: IGetReservationByRequest): UseQueryResult<IGetReservationByResponse, unknown> => {
  const query = useQuery({
    queryKey: ["getReservationBy", getReservationByQueries],
    queryFn: async () => {
      const response: AxiosResponse<IGetReservationByResponse> = await httpClient.get(getGetReservationByEndpoint(getReservationByQueries));
      return response.data;
    },
    retry: 0,
  });
  return query;
};
